<template>
  <div style="height: 100%">
    <iframe :src="fileName" style="height: 100vh; width: 100%" />
    <!-- <WebViewer :path="`/lib`" :url="fileName" /> -->
  </div>
</template>

<script>
// import WebViewer from '@/components/WebViewer.vue'
export default {
  name: 'Document',
  components: {
    // WebViewer
  },
  props: {},
  data() {
    return {
      fileName: '',
      fileNameMap: {
        manual: 'manual.pdf',
        FAQ: 'Gperf FAQ-v2.pdf',
      },
    }
  },
  created() {
    console.log('???!!')
    console.log(this.$route.params.name)
    this.fileName = this.fileNameMap[this.$route.params.name]
  },
  mounted() {
    // window.open(this.fileName)
  },
  methods: {},
}
</script>

<style lang="scss" scoped></style>
